<template>
   <div><slot /></div>
</template>

<script>
export default {
  name: "AppLayoutDefault"
}
</script>

<style scoped>

</style>